<main class="middle-content">
                  <div class="page_title_block">
                      <h1 class="page_title" style="color:#26272d">BROADCASTING MANAGEMENT</h1>
                  </div>
                  <div class="content-section">
                      <div class="outer-box">
                          <div class="global-table no-radius p0">
                              <div class="tab-content1">
                                  <div class="tab-pane1">
                                             <div class=row>
                                                 <div class="col-md-12">
                                                  <div class="two-btnline">
                                                      <!-- <div class="text-left" style="display: flex; float: right;" routerLink="/add-announcement">
                                                          <button type="submit" class="btn  btn-theme cus-mr" >Add Announcement</button>
                                                      </div> -->
                                                      <div class="row  d-flex justify-content-end w_100">

                                                        <button type="submit" class="btn  btn-theme " style="margin-right: -3%;" routerLink="/add-announcement" >Add Broadcast
                                                        </button>
                                                    </div>
                                                    </div>
                                                  </div>
              
                                                </div>
              
                                      <div class="table-responsive mt-3">
                                          <table class="table table-bordered" aria-describedby="user list">
                                              <thead>
                                                  <tr class="no_wrap_th">
                                                      <th scope="col">S.No.</th>  
                                                      <th scope="col">Page Name</th>
                                                      <th scope="col">Description</th>
                                                      
                                                      <th scope="col" class="action_td_btn3">Action</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr *ngFor="let items of announcementData | paginate:{itemsPerPage:20, currentPage:pageNumber, totalItems:announcementData?.length}; let i=index">
                                                      <td>{{20*(this.pageNumber-1)+i+1}}</td>
                                                      <td>{{items?.title || 'N/A'}}</td>
                                                      <td>{{items?.description || 'N/A'}}</td>
                                                      
              
                                                      <td class="action_td_btn3">
                                                                    <a routerLink="/view-announcement/{{items?.announcementsId}}" ><i class="fa fa-eye"
                                                                            aria-hidden="true"></i></a>
                                                                    <a routerLink="/edit-announcement/{{items?.announcementsId}}"><i class="fa fa-edit" aria-hidden="true"></i></a>
                                                                    <a (click)="delete(items?.announcementsId)"><i class="fa fa-trash" aria-hidden="true"></i></a>
                        
                        
                                                                </td>
                                                  </tr>
                                                  <tr *ngIf="announcementData?.length==0">
                                                    <td colspan="10" vertical-align="middle">
                                                        <div class="no-record">
                                                            <div class="no-recordin">
                                                                <H5 style="margin-left: 10%;">No record found</H5>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
              
                                                  
                                              </tbody>
                                          </table>
                                      </div>
                                      <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end" >
                                        <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                                    </div>
              
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </main>
              
              <footer>
                <div class="container-fluid">
                    <p class="copyright">Blockchain Project Development © 2021 All Rights Reserved.  <strong class="theme-text-color"></strong> 
                        </p>
                </div>
            </footer>
              
              <!-- delete_modal Start -->
              <div class="modal fade global-modal reset-modal" id="deleteModal">
                  <div class="modal-dialog max-WT-500">
                      <form class="change_password">
                          <div class="modal-content">
                              <div>
                                  <div class="modal-body">
                                      <h3 style="text-align: center; ">Delete Announcement </h3>
                                      <hr>
                                      <button type="button" class="close" data-dismiss="modal"
                                          style="margin-top: -85px;">&times;</button>
                                      <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                          <div class="w-100">
                                              <p style="font-size: 25px;">Are you sure you want to delete this Announcement?</p>
                                              <div>
                                                  <button type="submit" class="btn btn-info mr-2" (click)="deleteUser()">Yes</button>
                                                  <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
              <!-- delete_modal End -->
              
              
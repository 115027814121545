<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">USER TRADING DETAILS</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                      <div class="custom_tabs common-tabs">
                        <div class="row mb20 justify-content-center">
                            <div class="col-sm-12">
                                <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                                    <li class="nav-item flex-fill"> <a class="nav-link active show"
                                            [ngClass]="{'active': currTab=='GI'}" (click)="selectTab('GI')"
                                            href="javascript:;">General Information</a>
                                    </li>
                                    <li class="nav-item flex-fill"> <a class="nav-link active show"
                                            [ngClass]="{'active': currTab=='WalletTrading'}" (click)="selectTab('WalletTrading')"
                                            href="javascript:;">Wallet Trading</a>
                                    </li>
                                    <li class="nav-item flex-fill"> <a class="nav-link active show"
                                      [ngClass]="{'active': currTab=='Trading'}" (click)="selectTab('Trading')"
                                      href="javascript:;">Trading</a>
                              </li>
                              <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='Login'}" (click)="selectTab('Login')"
                                href="javascript:;">Login Session Activity</a>
                        </li>
                                </ul>
                            </div>
                        </div>
                      </div>
                      <!-- ******************** This is the filter section start here ********************* -->
                        <div class="user-main-head sec_head_new sec_head_new_after" >
                            <form [formGroup]="userForm">
                                <div class="row">
                                  <div class="col-md-2">
                                    <div class="head_flt_select">
                                      <div class="head_flt_select input-label input-label1">
                                          <span class="d-flex align-items-center" >Type : </span>
                                          <select formControlName="type" name="" id="" class="w-100 form-control">
                                            <option value="">Buy</option>
                                          </select>
                                      </div>
                                  </div>
                                  </div>
                                  <div class="col-md-2">
                                    <div class="head_flt_select">
                                      <div class="head_flt_select input-label input-label1">
                                          <span class="d-flex align-items-center" >Currency : </span>
                                          <select formControlName="currency" name="" id="" class="w-100 form-control">
                                            <option value="">BTC</option>
                                          </select>
                                      </div>
                                  </div>
                                  </div>
                                    <div class="col-md-5">
                                        <div class="row">
                                            <div class="col-md-5">
                                                <div class="head_flt_select">
                                                    <div class="head_flt_select input-label input-label1">
                                                        <span class="d-flex align-items-center" >Date of Transaction : </span>
                                                        <input type="date" formControlName="startdate">
                                                    </div>
                                                </div>
  
                                            </div>
                                            <div class="col-md-5">
  
                                                <div class="head_flt_select">
                                                    <div class="head_flt_select input-label input-label1">
                                                        <span class="d-flex align-items-center">To Date : </span>
                                                        <input type="date" formControlName="enddate">
                                                    </div>
                                                </div>
  
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="user-form2">
                                            <div class="row">
                                                <div class="col-md-12 user-lst">
                                                    <div class="two-btnline">
                                                          <button type="submit" class="btn  btn-theme cus-mr">SEARCH</button>
                                                            <button type="submit" class="btn  ml-1 btn-theme cus-mr"
                                                                >RESET</button>
  
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
        <!-- ******************** This is the filter section end here ********************* -->
  
        <div class="custom_tabs common-tabs">
          <div class="row mb20">
            <div class="col-sm-9">
  
            </div>
              <div class="col-sm-3 text-right">
                <div class="head_flt_select">
                  <div class="head_flt_select input-label">
                      <button type="submit" class="btn  btn-theme">EXPORT AS EXCEL </button>
                  </div>
              </div>
              </div>
          </div>
        </div>
  
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th scope="col">S.No</th>
                                        <th scope="col">Transaction ID                                      </th>
                                        <th scope="col">Transaction Type</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Base Coin</th>
                                        <th  scope="col">Executable Coin</th>
                                        <th  scope="col">Date and Time</th>
                                        <th scope="col" class="action_td_btn3">Action</th>
  
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of tradingDetail" let index i>
                                        <td>i+1</td>
                                        <td>{{data?.txnId || 'N/A'}}</td>
                                        <td>{{data?.txnType || 'N/A'}}</td>
                                        <td>{{data?.fees || 'N/A'}}</td>
                                        <td>{{data?.coinType || 'N/A'}}</td>
                                        <td>{{data?.coinType || 'N/A'}}</td>
                                        <td>{{data?.txnTime || 'N/A'}}</td>
                                        <td class="action_td_btn3">
                                          <a (click)="viewUserTrading(data?.txnId)"><i class="fa fa-eye" aria-hidden="true"
                                            ></i></a>
                                        </td>
  
                                    </tr>
  
                                </tbody>
                            </table>
                        </div>
  
                    </div>
                </div>
            </div>
        </div>
    </div>
  </main>
  
  <footer>
      <div class="container-fluid">
          <p class="copyright">Blockchain Project Development © 2021 All Rights Reserved.  <strong class="theme-text-color"></strong> 
              </p>
      </div>
  </footer>
  
  
  <!-- delete_modal Start -->
  <div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Remove User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to delete this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2"
                                        >Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
  </div>
  <!-- delete_modal End -->
  
  <!-- block_modal Start -->
  <div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Block User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to block this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2"
                                        >Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
  </div>
  
  <!-- active Start -->
  <div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Active User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to active this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2"
                                        >Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
  </div>
  
  <div class="modal global-modal reset-modal" id="enableDisableDeleteModal">
          <div class="modal-dialog max-WT-350">
              <div class="modal-content">
                  <div class="modal-header d-block text-center">
                      <h5 class="modal-title d-inline-block" >Activate</h5>
                      <h5 class="modal-title d-inline-block" >Deactivate</h5>
                      <h5 class="modal-title d-inline-block" >Delete</h5>
                  </div>
                  <div class="modal-body mt-0">
                      <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                          <div class="w-100">
                              <p >Are you sure you want to activate this agent?</p>
                              <p >Are you sure you want to deactivate this agent?</p>
                              <p >Are you sure you want to delete this agent?</p>
                              <div>
                                  <button type="submit"  class="btn mr-2 btn-info btn-theme"
                                      >Deactivate</button>
                                  <button type="submit"  class="btn mr-2 btn-info btn-theme"
                                      >Activate</button>
                                  <button type="submit"  class="btn mr-2 btn-info btn-theme"
                                      >Delete</button>
                                  <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <!-- active Start -->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Active User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to active this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
  
  
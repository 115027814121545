import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-all-user-trader',
  templateUrl: './all-user-trader.component.html',
  styleUrls: ['./all-user-trader.component.css']
})
export class AllUserTraderComponent implements OnInit {
  traderData: any=[];
  pageNumber:number=1
  length: any;
  traderForm: FormGroup;
  listing: any =[];
  spinner: any;

currentPage : any = 1
limit : any = 10
today = new Date().toISOString().split("T")[0];

  constructor(public route:Router,public service:MainService) { 

  }
 

  ngOnInit(): void {
    this.allTrader()
    this.traderForm = new FormGroup({
      'searchText': new FormControl(''),
      'startdate' : new FormControl(''),
      'enddate' : new FormControl(''),
      'userStatus' : new FormControl(''),

    })
  }

  // search() {
    
  //   var search = this.traderForm.value.searchText;
  //   if( this.traderForm.value.searchText){
  //     var url="wallet/get-all-transaction-history?userEmail="+search+'&page=0'+'&pageSize=5'
  //   }
   
  //   this.service.get( url ).subscribe((res: any) => {
  //      this.traderData = res.data.resultlist;
     
  //   })
  // }
  search() {
    let startdate = Date.parse(this.traderForm.value.startdate);
    let enddate = Date.parse(this.traderForm.value.enddate);
    // let startdate =   this.traderForm.value.startdate ? new Date(this.traderForm.value.startdate).toISOString() : '';
    // let enddate = this.traderForm.value.enddate ? new Date(this.traderForm.value.enddate).toISOString() : '';
    var search = this.traderForm.value.searchText;
    let countryDa = this.traderForm.value.country;

    let url = `wallet/get-all-transaction-history?coinName=${
      search
    }&page=${this.currentPage-1}&pageSize=10
     ${this.traderForm.value.startdate ? "&fromDate=" + startdate : ""}
    ${this.traderForm.value.enddate ? "&toDate=" + enddate : ""}
    ${this.traderForm.value.country ? "&country=" + countryDa : ""}
    ${
      this.traderForm.value.userStatus
        ? "&txnType=" + this.traderForm.value.userStatus
        : ""
    }
    `;

    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        this.service.hideSpinner();
        this.traderData=res['data']['resultlist'];

        console.log("kfg", this.listing);
        // this.totalRecords = res.data.totalCount;
        this.length = res.data.totalCount;
      },
      (err) => {
        this.service.hideSpinner();
        // this.listing = [];

        // this.totalRecords = err.data.totalCount;
      }
    );
  }

  changePage(e){
    this.currentPage = e
    this.allTrader()
  }
  // reset(){
  //   this.traderForm.reset()
  //   this.allTrader()
  // }
  reset() {
    if (
      this.traderForm.value.startdate ||
      this.traderForm.value.enddate ||
      this.traderForm.value.searchText ||
      this.traderForm.value.userStatus
    ) {
      this.traderForm.reset({
        startdate: "",
        userStatus: "",
        enddate: "",
        searchText: "",
      });
      this.allTrader();
    }
  }
  // all trader
  allTrader(){
    // var search = this.traderForm.value.searchText;
    var url = "wallet/get-all-transaction-history?"+`&page=${this.currentPage - 1}`+`&pageSize=${this.limit}`
    this.service.showSpinner() 
    this.service.get(url).subscribe((res)=>{
      this.service.hideSpinner()
      console.log('df', res);
     
      if(res['status']==200){
        this.traderData=res['data']['resultlist'];
        this.length=res['data']['totalCount']
        console.log('k', this.length);
        
        
      }
      
    },(err)=>{
      if(err['status']==401){
        this.service.hideSpinner()
        this.service.toasterErr('Unauthorized Access')
      }
      else{
        this.service.toasterErr('Something Went Wrong');
     }
    })
  }
  pagination(page){
    this.traderData=''
    this.pageNumber=page;
    this.allTrader()
  }
  exportAsXLSX() {
    let dataArr = [];
    this.traderData.forEach((element, ind) => {

      dataArr.push({
        "S no": ind + 1,
        "Transaction Id":element.basicTradeHistoryId,
        
        "Transaction Type":element.orderType,
        // "Ticket ID": element.ticketId ? element.ticketId : '',
        // "Customer Name": element.firstName + '' + element.lastName ? element.lastName : '',
       "User Email": element.userEmail ? element.userEmail : 'N/A',
         //"Status": element.userStatus == 'ACTIVE' ? 'Active' : 'Inactive',
         "Base Coin Amount":element.baseCoinAmmount,
         "Exec Coin Amount":element.execCoinamount,
        'Base Coin Name': element.baseCoinName,
        'Executable Coin Name': element.execCoinName,
        "Date": element.creationTime ? element.creationTime.slice(0, 10) : 'N/A',
        'Status':element.status,
       
     
      })
    })

    this.service.exportAsExcelFile(dataArr, 'All User Trader');
    console.log(dataArr)
  }
  copyToClipBoard(text : string){
    navigator.clipboard.writeText(text)
    this.service.toasterSucc("Copied to clipboard")
  }
}

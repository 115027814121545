<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">Transaction History</h1>
    </div>

    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">

                        <!-- <form [formGroup]="traderForm" class="all-form">
                            <div class="row form-group" style="width: 100%;">
                                <div class="col-md-4">
                                    <div class="serch-boxuser">
                                        <div class="input-group filter_search_group">
                                            <input type="text" class="form-control" placeholder="Search by email"
                                                formControlName="searchText">
                                            <div class="input-group-append">
                                                <button class="btn btn_search_group" type="button"><img
                                                        src="assets/img/icon-search.png" alt="Search"></button>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div class="col-md-4 user-lst text-left">
                                    <div class="two-btnline text-left">
                                        <div class="text-left">
                                            <button type="submit" class="btn  btn-theme cus-mr"
                                                (click)="search()">Search</button>
                                        </div>
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label" style="margin-left: 1%">
                                                <button type="submit" class="btn  btn-theme" (click)="reset()">Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row  d-flex justify-content-end w_100">

                                    <button type="submit" class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS
                                        EXCEL
                                    </button>
                                </div>
                            </div>
                        </form> -->
                        <div class="user-main-head sec_head_new sec_head_new_after">
                            <form [formGroup]="traderForm">
                                <div class="row form-group">
                                    <div class="col-lg-3 col-md-4 col-sm-6 ">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center"
                                                    style="margin-left: 5px!important">From Date </span>

                                                <input type="date" class="form-control form"
                                                    formControlName="startdate" [max]="today">
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">

                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center">To Date</span>
                                                <input type="date" class="form-control form" [max]="today"
                                                [readonly] = "!this.traderForm.controls['startdate'].value"
                                                [min]="traderForm.controls['startdate'].value"
                                                    formControlName="enddate">
                                            </div>
                                        </div>

                                    </div>
                                    <br>

                                    <div class="col-md-3 " style="margin-top:21px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <select formControlName="searchText" class="form-control form1">
                                                    <option value="">Select Coin</option>
                                                    <option value="BTC">BTC</option>
                                                    <option value="AVT">AVT</option>
                                                    <option value="BNB">BNB</option>
                                                    <option value="ETH">ETH</option>
                                                    <option value="USDT">USDT</option>

                                                </select>    
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-3 " style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <select formControlName="country" class="form-control form1">
                                                    <option value="">Select Country</option>
                                                    <option *ngFor="let code of products" value="{{code.name}}">{{code?.name}}</option>
                                                
                                                   
                                                </select>    
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-md-3 " style="margin-top:21px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <select formControlName="userStatus" class="form-control form1">
                                                    <option value="">Select Status</option>
                                                    <option value="DEPOSIT">Deposit</option>
                                                    <option value="WITHDRAW">Withdraw</option>
                                                    <!-- <option value="UNVERIFIED">Unverified</option> -->
                                                    <!-- <option value="DELETED">Delete</option> -->
                                                </select>    
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row col-md-12 justify-content-end">
                                        <div class=" " style="margin-top:26px;">
                                            <div class="serch-boxuser">
                                                <div class="input-group filter_search_group">
                                                    <div class="text-left" style="margin-left: 1%">
                                                        <button type="submit" class="btn  btn-theme cus-mr"
                                                            (click)="pageNumber = 1;search()">Search</button>
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class=" " style="margin-top:26px;">
                                            <div class="serch-boxuser">
                                                <div class="input-group filter_search_group">
                                                    <div class="head_flt_select input-label" style="margin-left: 1%">
                                                        <button type="submit" class="btn  btn-theme" (click)="reset()">Reset
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class=" " style="margin-top:26px;">
                                            <div class="serch-boxuser">
                                                <div class="input-group filter_search_group">
                                                    <div class="head_flt_select input-label" style="margin-left: 7%">
                                                        <button type="submit" class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS EXCEL
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-12 user-lst p-0" style="margin-top: 18px;display: flex;justify-content: end;padding-right: 21px !important;">
                                        <div class="two-btnline">
                                            <div class="text-left" style="margin-left: 1%">
                                                <button type="submit" class="btn  btn-theme cus-mr"
                                                    (click)="search()">Search</button>
                                            </div>
                                            <div class="head_flt_select">
                                                <div class="head_flt_select input-label" style="margin-left: 1%">
                                                    <button type="submit" class="btn  btn-theme" (click)="reset()">Reset
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="head_flt_select">
                                                <div class="head_flt_select input-label" style="margin-left: 1%">
                                                    <button type="submit" class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS EXCEL
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>

                            </form>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th scope="col">S.No</th>
                                        <th scope="col" class="WIP">Transaction ID </th>
                                        <th scope="col">Transaction Hash</th>
                                        <th scope="col">Transaction Type</th>
                                        <th scope="col" class="WIP">Coin Name</th>
                                        <th scope="col">User Email </th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Date and Time</th>
                                        <th scope="col">Status</th>
                                        <!-- <th scope="col">Base Coin</th>
                                        <th scope="col">Executable Coin</th>
                                        <th scope="col" class="action_td_btn3">Status</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let data of traderData | paginate:{itemsPerPage:limit, currentPage:currentPage, totalItems:length}; let i=index">
                                        <td>{{10*(currentPage-1)+i+1}}</td>

                                        <td>{{data?.txnId || 'N/A'}}</td>
                                        <td>
                                            <div class="d-flex justify-content-center ">
                                                <p class="ellipsis">{{data?.txnHash == 'null' ? 'N/A' : data?.txnHash || 'N/A'}}</p><i *ngIf="data?.txnHash != 'null' || !data?.txnHash" (click)="copyToClipBoard(data?.txnHash)" class="fa fa-clone" style="margin: 3px;cursor:pointer"></i>
                                            </div>
                                        </td>
                                        <td>{{data?.txnType || 'N/A'}}</td>
                                        <td>{{data?.coinType || 'N/A'}}</td>
                                        <td>{{data?.userEmail || 'N/A'}}</td>
                                        <td>{{data?.amount || 'N/A'}}</td>
                                        <td>{{data?.txnTime | date: 'medium'}}</td>
                                        <td>{{data?.status || 'N/A'}}</td>
                                        <!-- <td>{{data?.baseCoinName}}</td>
                                        <td>{{data?.execCoinName}}</td>
                                        <td>{{data?.status }} -->
                                        <!-- </td> -->
                                    </tr>
                                    <tr *ngIf="traderData?.length==0">
                                        <td colspan="10" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5 style="margin-left: 10%;">No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end">
                            <pagination-controls (pageChange)="changePage($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<footer>
    <div class="container-fluid">
        <p class="copyright">Blockchain Project Development © 2021 All Rights Reserved. <strong
                class="theme-text-color"></strong>
        </p>
    </div>
</footer>

<!-- active Start -->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Active User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to active this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Project / Add</h1>
        </div>
        <div class="content-section">
            <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
                <div class="  mb40">
                    <div class="add-store-block input-style mb20 mt20 view-label-align">
                        <form [formGroup]="addSwatchesForm" #f="ngForm">
                        
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright ">Name  <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter Name"
                                            formControlName="title" (keypress)="commonService.preventSpace($event)"
                                            maxlength="60">
                                            <span class="error">
                                                <p *ngIf="addSwatchesForm.get('title').hasError('required') && (addSwatchesForm.get('title').dirty || addSwatchesForm.get('title').touched )"
                                                    class="error" padding>*Name is required</p>
                                            </span> 
                                            <span class="error">
                                                    <p *ngIf="addSwatchesForm.get('title').hasError('pattern') && (addSwatchesForm.get('title').dirty || addSwatchesForm.get('title').touched )"
                                                        class="error" padding>*Please enter valid name</p>
                                                </span>
                                    </div>
                                </div>
                            </div>
                          
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright ">Date  <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group">
                                        <input type="datetime-local" class="form-control"   [min]="today"
                                            formControlName="date" 
                                            >
                                    </div>
                                </div>
                            </div>
                          
                        </form>
                    </div>
                    <div class="text-center mt40 mb40">
                        <button class="btn btn-large  max-WT-150 btn-theme" routerLink="/plan-list">Back</button>
                        <button class="btn btn-large  max-WT-150 btn-theme" [disabled]="!addSwatchesForm.valid"
                            (click)="addSwatches()">Add</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
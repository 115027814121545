<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Project  / View</h1>
        </div>
        <div class="content-section">
            <div class="order-view mrgn-0-auto setting-page">
                <div class="mb40">
                    <!-- <div class="admin-profile">
                        <h4 class="mb20 text-center">View Profile</h4>
                    </div> -->
                    <div class="add-store-block input-style view-label-align  max-WT-700 ">
                        <!-- <div class="form-group row align-items-baseline">
                            <div class="col-md-12" style="text-align: center">
                                <img src="{{categoryDetails?.profilePic || 'assets/img/profile-img.jpg' }}"
                                    style="height: 100px;width: 100px; border-radius: 50%; border: 1px solid black!important;"
                                    alt="profile image">
                            </div>
                        </div> -->
                        
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4 textalignright ">Name<span>:</span></label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group">
                                    <!-- <p style="padding: 0px 5px!important;">
                                        {{categoryDetails?.categoryName || '--'}}</p> -->
                                    <p style="padding: 0px 5px!important;">
                                        {{editData?.name || '--'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4 textalignright "> Date & Time<span>:</span></label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group">
                                    <!-- <p style="padding: 0px 5px!important;">
                                        {{categoryDetails?.categoryName || '--'}}</p> -->
                                    <p style="padding: 0px 5px!important;    max-height: 200px;
                                    overflow-y: scroll;">
                                        {{(editData?.createTime | date:'medium') || '--'}}</p>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="form-group row align-items-baseline">
                            <label class="col-md-4 textalignright ">Update Date & Time<span>:</span></label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group">
                                
                                    <p style="padding: 0px 5px!important;    max-height: 200px;
                                    overflow-y: scroll;">
                                        {{(editData?.updationTime | date:'medium') || '--'}}</p>
                                </div>
                            </div>
                        </div> -->
                      
                    </div>
                    <div class="text-center mt40 mb40">
                        <button class="btn btn-large  max-WT-150 btn-theme" routerLink="/plan-list">Back</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
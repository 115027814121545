<main class="middle-content">
    <!-----page title start----->
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">View News</h1>
    </div>
    <!-----page title end----->
    <!------ table responsive start ----->
    <div class="content-section">
     
            <div class="outer-box shadow">
                <div class="custom_tabs common-tabs">
                    <div class="tab-content card" style="padding: 2%;  margin: auto;">

                        <div class="myAlign">
                            <div class="form-group row">
                                <label class="col-md-5 bold">Title</label>
                                <span class="col-md-1 sm-d-none">:</span>
                                <span class="col-md-4">
                                    {{newsDetail?.title}}
                                    
                                </span>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-5 bold">Url</label>
                                <span class="col-md-1 sm-d-none">:</span>
                                <span class="col-md-4">
                                    <a [href]="newsDetail?.url" target="_blank">{{newsDetail?.url}}</a>
                                    
                                    
                                </span>
                            </div>


                            <div class="form-group row">
                                <label class="col-md-5 bold">Image/Video</label>
                                <span class="col-md-1 sm-d-none">:</span>
                                <span class="col-md-6">
                                    
                                    <div class="row">
                                        <div class="col-md-8 col-lg-8">
                                            <!-- <img [src]="newsDetail?.imageUrl" alt=""
                                                [ngClass]="{advertizeImage: (imageuploaded == true)}"
                                                style="width: 100px; margin-top: 10px;" /> -->
                                                <img *ngIf="newsDetail?.mediaTypes == 'ARTICLES'" [src]="newsDetail.imageUrl" alt="img" width="50px">
                                                <video *ngIf="newsDetail?.mediaTypes == 'VIDEO'" width="50px"  [src]="newsDetail.imageUrl" style="width: 100px; margin-top: 10px;">
                                                </video>
                                        </div>
                                    </div>
                                </span>
                            </div>
                            
                            <div class="form-group row">
                                <label class="col-md-5 bold">Media Type</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6 col-lg-6" >
                                    <!-- <ck-editor name="editor1" skin="moono-lisa" [(ngModel)]="description" config.readOnly = true; language="en"  [fullPage]="true"></ck-editor> -->
                                  <!-- <section class="news_description" [innerHTML]="sanitizer.bypassSecurityTrustHtml(description)">

                                  </section> -->
                                  <p class="news_description" >{{newsDetail?.mediaTypes}}</p>
                                   
                                </span>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-5 bold">Description</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6 col-lg-6" >
                                    <!-- <ck-editor name="editor1" skin="moono-lisa" [(ngModel)]="description" config.readOnly = true; language="en"  [fullPage]="true"></ck-editor> -->
                                  <!-- <section class="news_description" [innerHTML]="sanitizer.bypassSecurityTrustHtml(description)">

                                  </section> -->
                                  <p class="news_description" >{{description}}</p>
                                   
                                </span>
                            </div>


                        </div>
                        <div class="text-center">
                            <button class="btn btn-theme" type="button" [routerLink]="['/news-letter-list']">Back</button>
                            <button class="btn btn-theme ml-3" type="button"  (click)="editAdvertisement()"
                               >Edit</button>
                        </div>
                    </div>
                </div>
            </div>
      
    </div>
    <!------ table responsive end ----->
</main>
<footer>
    <div class="container-fluid">
        <p class="copyright">Blockchain Project Development © 2021 All Rights Reserved.<strong
                class="theme-text-color"></strong>
        </p>
    </div>
</footer>
import { MainService } from './../../../provider/main.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';


declare var $: any
@Component({
  selector: 'app-list-project-plan',
  templateUrl: './list-project-plan.component.html',
  styleUrls: ['./list-project-plan.component.css']
})
export class ListProjectPlanComponent implements OnInit {
  searchForm: FormGroup;
  bodyListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;

  remaimingId: any;

  // hospitalIds: any = [];
  // // checked: any
  // isCheckedAll: any = false;
  // checked: any
  // toDate: string | number | Date;
  // newDate: string | number | Date;
  // oldDate: string | number | Date;
  // fromDate: string | number | Date;
  // today: string
  today = new Date().toISOString().split('T')[0]
  userStatus: any = 'ACTIVE';
 
//    tooltips = require('tooltip')
 
// tooltip()

  constructor(private router: Router, public commonService: MainService) { }

  ngOnInit() {
    this.searchFormValidation();
    // this.searchForm.get('fromDate').valueChanges.subscribe((x)=>{
    //   console.log('x',x);
    //   this.fromDate = x
    // })
    this.getEmbellishmentList();
    // this.commonService.BlockFuture()
    // this.today = new Date().toISOString()
    console.log(this.today)
  }

  // Search by Date
  //    searchByDate(){

  //  }

  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(''),
      status: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    });
  }
  searchFormSubmit() {

    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      //   if(this.searchForm.value.fromDate > this.searchForm.value.toDate){
      //     this.commonService.errorToast('To date should be greater than From date')
      //     // return
      //     // this.searchForm.value.fromDate = '';
      //     // this.messege = "please enter right date"
      //   }
      //   else if(this.searchForm.value.fromDate <= this.searchForm.value.toDate){

      //  this.oldDate = new Date(this.searchForm.value.fromDate).toISOString()
      //  this.newDate = new Date(this.searchForm.value.toDate+'T23:59:59.999Z')
      //  console.log('this.oldDate',this.oldDate );
      //  console.log('this.newDate',this.newDate );
      //       }
      // this.fromDate =this.searchForm.value.fromDate
      this.getEmbellishmentList()
    }
  }
  searchFormReset() {
    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.searchForm.reset({
        search: '',
        status: '',
        fromDate: '',
        toDate: ''
      });
      this.getEmbellishmentList()
    }
  }

  selectStatus() {
    this.currentPage = 1
  }
  // -------------------- get category list --------------------- //
  getEmbellishmentList() {
    let apiReqUrl: any = `wallet/wallet/get-all-dashboard-remaining`

    this.commonService.showSpinner();

    // remove empty keys from request body
    // apiReqData = this.commonService.removeEmptyKey(apiReqData)
    this.commonService.get(apiReqUrl).subscribe((res: any) => {
      console.log("get category management list response ==>", res)
      if (res.status == 200) {
        this.bodyListArray = res.data.List ? res.data.List : '';
        this.totalItems = res.data.count
        this.commonService.hideSpinner();
        // this.commonService.toasterSucc(res.responseMessage);
      } else {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    },(err)=>{
      console.log(err);
      
      if(err.status == 404){
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }
      
    })
  }

  pagination(event) {
    console.log(event)
    this.currentPage = event;
    this.getEmbellishmentList()
  }

  addBody() {
    this.router.navigate(['/plan-add'])
  }
  // view category
  viewBody(remaimingId) {
    // alert(remaimingId)
    this.router.navigate(['/plan-view'], { queryParams: { remaimingId: remaimingId } })
  }
 
  // edit category
  editBody(remaimingId) {
    this.router.navigate(['/plan-edit'], { queryParams: { remaimingId: remaimingId } })
  }
  // editCategory(remaimingId) {
  //   this.router.navigate(['/embellishment/edit-embellishment'])
  // }
  // blockUnblockUserModal(){

  // }
  // ------------------------------- delete hospital ----------------------------- //
  // deleteCategoryModal(remaimingId) {
  //   $('#deleteCategory').modal('show')
  //   this.remaimingId = remaimingId
  // }
  // deleteCategory() {
  //   // let data = {
  //   //   hospitalId: this.hospitalId
  //   // }
  //   // console.log(data)
  //   let apiReqUrl: any = "static/delete-announcement?remaimingId="+this.remaimingId
  //   this.commonService.showSpinner();
  //   this.commonService.delete(apiReqUrl).subscribe((res: any) => {
  //     // console.log("delete category user response ==>", res)
  //     $('#deleteCategory').modal('hide');
  //     if (res.status == 200) {
  //       this.getEmbellishmentList()
  //       this.commonService.toasterSucc(res.message);
  //     } else {
  //       this.commonService.hideSpinner();
  //       this.commonService.toasterErr(res.message)
  //     }
  //   })
  // }
}

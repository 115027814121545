import { MainService } from './../../../provider/main.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-project-plan',
  templateUrl: './view-project-plan.component.html',
  styleUrls: ['./view-project-plan.component.css']
})
export class ViewProjectPlanComponent implements OnInit {
  remaimingId: any;
  skirtListArray: any;
  colorList : any = [];
  editData:any
  constructor(private activatedroute: ActivatedRoute, public commonService: MainService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.remaimingId = res.remaimingId;
    })
    this.viewBlog()
  }

  // edit faq english
 viewBlog(){
  this.commonService.showSpinner()
this.commonService.get("wallet/wallet/get-all-dashboard-remaining-by-id?remaimingId="+this.remaimingId).subscribe((res:any)=>{
  if (res.status=200) {
    console.log('jjh', res);
    this.editData=res.data;
    this.commonService.hideSpinner()

  }
 },err=>{

   this.commonService.hideSpinner();
   if(err['status']=='401'){
    //  this.commonService.onLogout();
     this.commonService.toasterErr('Unauthorized Access');
   }else{
   this.commonService.toasterErr('Something Went Wrong');
}
 })

} 

}

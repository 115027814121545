<main class="middle-content">
    <!-----page title start----->
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">Add News</h1>
    </div>
    <!-----page title end----->
    <!------ table responsive start ----->
    <div class="content-section">
        <form [formGroup]="addForm">
            <div class="outer-box shadow">
                <div class="custom_tabs common-tabs">
                    <div class="tab-content card" style="padding: 2%;  margin: auto;">

                        <div class="myAlign">
                            <div class="form-group row">
                                <label class="col-md-5 bold">Title</label>
                                <span class="col-md-1 sm-d-none">:</span>
                                <span class="col-md-4">
                                    <input type="text" class="form-control" placeholder="Title" formControlName="title"
                                        maxlength="100">
                                    <div
                                        *ngIf="addForm.controls['title'].hasError('required') && (addForm.controls['title'].touched ||addForm.controls['title'].dirty)">
                                        <span class="error">*Please enter title.</span>
                                    </div>
                                </span>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-5 bold">Url</label>
                                <span class="col-md-1 sm-d-none">:</span>
                                <span class="col-md-4">
                                    <input type="text" class="form-control" placeholder="News Url" formControlName="url"
                                        maxlength="100">
                                    <div
                                        *ngIf="addForm.controls['url'].hasError('required') && (addForm.controls['url'].touched ||addForm.controls['url'].dirty)">
                                        <span class="error">*Please enter url.</span>
                                    </div>
                                    <!-- <div
                                        *ngIf="addForm.controls['url'].hasError('pattern') && (addForm.controls['url'].touched ||addForm.controls['url'].dirty)">
                                        <span class="error">*Please enter valid url.</span>
                                    </div> -->
                                </span>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-5 bold">Media Type</label>
                                <span class="col-md-1 sm-d-none">:</span>
                                <span class="col-md-4">
                                    <select  class="form-control" placeholder="Media Type" formControlName="mediaTypes"
                                       >
                                       <option value="" selected>Select Media Type</option>

                                       <option value="VIDEO">VIDEO</option>
                                       <option value="ARTICLES">ARTICLES</option>

                                    </select>
                                    <div
                                        *ngIf="addForm.controls['mediaTypes'].hasError('required') && (addForm.controls['mediaTypes'].touched ||addForm.controls['mediaTypes'].dirty)">
                                        <span class="error">*Please media type.</span>
                                    </div>
                                    <!-- <div
                                        *ngIf="addForm.controls['url'].hasError('pattern') && (addForm.controls['url'].touched ||addForm.controls['url'].dirty)">
                                        <span class="error">*Please enter valid url.</span>
                                    </div> -->
                                </span>
                            </div>


                            <div class="form-group row">
                                <label class="col-md-5 bold">Upload Image</label>
                                <span class="col-md-1 sm-d-none">:</span>
                                <span class="col-md-6">
                                    <input #file type="file" formControlName="image" accept='image/*,video/mp4,video/x-m4v,video/*'
                                        (change)="uploadImg($event)" />
                                    <div class="row">
                                        <div class="col-md-8 col-lg-8">
                                            <img [src]="editImage" alt=""
                                                
                                                style="width: 100px; margin-top: 10px;" />

                                               
                                                <video *ngIf="editImage" width="320"  [src]="editImage" style="width: 100px; margin-top: 10px;">


</video>
                                        </div>
                                    </div>
                                </span>
                            </div>

                            <div class="form-group row">
                                <label class="col-md-5 bold">Description</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">
                                    <!-- <ck-editor name="editor1" skin="moono-lisa" language="en" formControlName="description" [fullPage]="true"></ck-editor> -->
                                    <textarea class="form-control" formControlName="description" name="" id=""
                                        cols="30" rows="5"></textarea>

                                    <div
                                        *ngIf="addForm.controls['description'].hasError('required') && (addForm.controls['description'].touched ||addForm.controls['description'].dirty)">
                                        <span class="error">*Please enter description.</span>
                                    </div>
                                </span>
                            </div>


                        </div>
                        <div class="text-center">
                            <button class="btn btn-theme" type="button" [routerLink]="['/news-letter-list']">Back</button>
                            <button class="btn btn-theme ml-3" type="button" [disabled]="addForm.invalid || !editImage"
                                (click)="addAdvertisement()">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <!------ table responsive end ----->
</main>

<footer>
    <div class="container-fluid">
        <p class="copyright">Blockchain Project Development © 2021 All Rights Reserved.<strong
                class="theme-text-color"></strong>
        </p>
    </div>
</footer>
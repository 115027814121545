import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-news-letter-add',
  templateUrl: './news-letter-add.component.html',
  styleUrls: ['./news-letter-add.component.css']
})
export class NewsLetterAddComponent implements OnInit {
  addForm: FormGroup
  advertisementList: any;

  imgSrc: any = []
  productImageDto: any = []
 
  image: string;
  imageuploaded: boolean = false;
  constructor(private router: Router, public mainService: MainService) { }

  ngOnInit() {

    this.addBannerFormValidation();
  }

  //----- validation -------//
  addBannerFormValidation() {
    this.addForm = new FormGroup({
      title: new FormControl("", [Validators.required]),
      url : new FormControl("", [Validators.required]),
      description: new FormControl("", [Validators.required]),
      image: new FormControl(""),
      mediaTypes: new FormControl('',Validators.required)
    
     
      
    })

  }

  addAdvertisement() {
    if(this.imgSrc[0] == 'assets/img/loaderadvertisement.gif'){
      this.mainService.toasterInfo("Please wait while image is uploading")
      return
    }
    let url = "account/add-news-letter"
    const data = {
      "description": this.addForm.value.description,
      "imageUrl": this.editImage,
      "isDeleted": false,
      "publishedTime": new Date(),
      "title": this.addForm.value.title,
      'url' : this.addForm.value.url,
      'mediaTypes' : this.addForm.value.mediaTypes
    }
    console.log(data)
    this.mainService.showSpinner();
    this.mainService.postApi(url, data).subscribe((res) => {
      if (res["status"] == 200) {
        this.mainService.hideSpinner();
        this.mainService.toasterSucc(res["responseMessage"]);
        this.router.navigate(['/news-letter-list'])
      } else {
        this.mainService.hideSpinner();
        this.mainService.toasterErr(res["responseMessage"]);
      }
    });
  }
  editImage:any
  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append("file", img);
    this.mainService.showSpinner();
    this.mainService.postApi("account/upload-file", fb).subscribe(
      (res) => {
        // this.mainService.hideSpinner();
        if (res["status"] == "200") {
          this.editImage = res["data"];
          this.mainService.hideSpinner();
        }
      },
      (err) => {
        this.mainService.hideSpinner();
        if (err["status"] == "401") {
          this.mainService.onLogout();
          this.mainService.toasterErr("Unauthorized Access");
        } else {
          this.mainService.toasterErr("Something Went Wrong");
        }
      }
    );
  }


  back() {
    this.router.navigate(['/advertisement-list'])
  }

}

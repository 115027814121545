import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-news-letter-edit',
  templateUrl: './news-letter-edit.component.html',
  styleUrls: ['./news-letter-edit.component.css']
})
export class NewsLetterEditComponent implements OnInit {
  addForm: FormGroup
  advertisementList: any;
  

  
 
  imgSrc: any = []
  productImageDto: any = []
 
  image: string;
  imageuploaded: boolean = true;
  newsId : any
  
  constructor(private router: Router, public mainService: MainService,private activated : ActivatedRoute) { 
    this.activated.queryParams.subscribe((res)=>{
      this.newsId  = res.newsId
    })
  }

  ngOnInit() {

    this.addBannerFormValidation();
    this.getNews()
  }

  //----- validation -------//
  addBannerFormValidation() {
    this.addForm = new FormGroup({
      title: new FormControl("", [Validators.required]),
      url: new FormControl("", [Validators.required]),
      description: new FormControl("", [Validators.required]),
      image: new FormControl(""),
      mediaTypes: new FormControl('',Validators.required)

    
      // postedDate: new FormControl(new Date(), [Validators.required]),
      
    })

  }

  //------ add google map for location with latitude and longitude-----//


  getNews() {
    let url = `account/get-news-letter-by-id?newsLetterId=${this.newsId}`
    
   
    this.mainService.showSpinner();
    this.mainService.get(url).subscribe((res) => {
      if (res["status"] == 200) {

        this.addForm.patchValue({
          title: res['data']['title'],
          url : res['data']['url'],
          description: res['data']['description'],
          mediaTypes : res['data']['mediaTypes']
        })
        this.editImage = res["data"]["imageUrl"]
        this.mainService.hideSpinner();
        this.mainService.toasterSucc(res["message"]);
       
      } else {
        this.mainService.hideSpinner();
        this.mainService.toasterErr(res["message"]);
      }
    });
  }




 
  //-------- add  advertisement --------//
  addAdvertisement() {
    if(this.editImage == 'assets/img/loaderadvertisement.gif'){
      this.mainService.toasterInfo("Please wait while image is uploading")
      return
    }
    let url = `account/update-news-letter?newsLetterId=${this.newsId}`
    const data = {
      "description": this.addForm.value.description,
      "imageUrl": this.editImage,
      'url' : this.addForm.value.url,
      "title": this.addForm.value.title,
      'mediaTypes' : this.addForm.value.mediaTypes
    }
    console.log(data)
    this.mainService.showSpinner();
    this.mainService.postApi(url, data).subscribe((res) => {
      if (res["status"] == 200) {
        this.mainService.hideSpinner();
        this.mainService.toasterSucc(res["message"]);
        this.router.navigate(['/news-letter-list'])
      } else {
        this.mainService.hideSpinner();
        this.mainService.toasterErr(res["message"]);
      }
    });
  }

  //--------- upload image functionality-------//
  editImage:any
  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append("file", img);
    this.mainService.showSpinner();
    this.mainService.postApi("account/upload-file", fb).subscribe(
      (res) => {
        // this.mainService.hideSpinner();
        if (res["status"] == "200") {
          this.editImage = res["data"];
          this.mainService.hideSpinner();
        }
      },
      (err) => {
        this.mainService.hideSpinner();
        if (err["status"] == "401") {
          this.mainService.onLogout();
          this.mainService.toasterErr("Unauthorized Access");
        } else {
          this.mainService.toasterErr("Something Went Wrong");
        }
      }
    );
  }


  back() {
    this.router.navigate(['/advertisement-list'])
  }

}

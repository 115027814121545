<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">MANAGE FEE</h1>
    </div>
    <div class="content-section" style="margin-top: 6%">
        <div class="tab-pane active show">
         
            <div class="card" style="box-shadow: 0 0.5rem 1rem;">
                <div class="card-body">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">
                       
                            <div class="outer-box">
                                <div class="global-table no-radius p0">
                                    <div class="border  border-dark " style="margin-top: 3%;">
                                        <div class="row form-group"
                                            style="display: flex;justify-content: space-around;margin-top: 6%;">
                                            <div class="col-md-8">
                                                <p style="white-space: nowrap; margin-left: 3%;position:relative;top: 5px;"> Minimum Withdrawal
                                                    Amount</p>
                                            </div>
                                            <div class="col-md-4" style="display: flex;justify-content: center;">
                                                <button type="submit" class="btn  btn-theme cus-mr"
                                                    routerLink="/min-withdrawl-amount">Standard</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="border  border-dark " style="margin-top: 16%;">
                                        <div class="row form-group"
                                            style="display: flex;justify-content: space-around;margin-top: 6%;">
                                            <div class="col-md-8">
                                                <p style="margin-left: 3%;position:relative;top: 5px;">Withdrawal Fee</p>
                                            </div>
                                            <div class="col-md-4" style="display: flex;justify-content: center;">
                                                <button type="submit" class="btn  btn-theme cus-mr"
                                                    routerLink="/standard-trading-fee">Standard</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                    </div>
                </div>
               </div>
               </div>
         
        </div>
    </div>

</main>

<footer>
    <div class="container-fluid">
        <p class="copyright">Blockchain Project Development © 2021 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>
<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">MINIMUM WITHDRAWAL FEE  UPDATE</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="row view_block">
                    <div class="col-4">
                        <h5>Withdrawal Fee</h5>
                    </div>
                    <div class="col-8 col-sm-12">
                        <input type="number"  maxlength="7" required class="form-control" [(ngModel)]="withdrawlFee"
                                             >
                    </div>
                    <div class="col-12 col-sm-12 text-center mt-3">
                        <button type="submit" class="btn  btn-theme cus-mr"  (click)="submitFee()">Submit</button>

                    </div>
                </div>
                <!-- <div class="tab-content1">
                    <div class="tab-pane1">
                      
                            <div class="col-md-3">
                                <div class="head_flt_select">
                                    <div>
                                        <span class="labelcss">Withdrawal Fee:</span>
                                        <input type="text" (keypress)="numberOnly($event)" maxlength="4" required class="form-control" [(ngModel)]="withdrawlFee"
                                            class="formcss" >
                                        <h3 style="margin-left: 295%;margin-top: -17%;">%</h3>
                                       
                                    </div>
                                </div>
                            </div>
                           
                            <button type="submit" class="btn  btn-theme cus-mr"style="width: 33%;margin-left: 32%;margin-top: 5%;" (click)="submitFee()">Submit</button>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</main>

<footer>
    <div class="container-fluid">
        <p class="copyright">Blockchain Project Development © 2021 All Rights Reserved.  <strong class="theme-text-color"></strong> 
            </p>
    </div>
</footer>
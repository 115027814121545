import { MainService } from './../../../provider/main.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-project-plan',
  templateUrl: './add-project-plan.component.html',
  styleUrls: ['./add-project-plan.component.css']
})
export class AddProjectPlanComponent implements OnInit {
  addSwatchesForm: FormGroup
  imageUrl
  
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  today: any = new Date().toISOString().slice(0, 16)

  constructor(private router: Router, public commonService: MainService) { }

  ngOnInit() {
    this.addSwatchesFormValidation();
  
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      // 'colorName': new FormControl('', [Validators.required]),
      // 'author': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/)]),
      'title': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/)]),
      'date': new FormControl('', [Validators.required]),

      // 'description': new FormControl('',[Validators.required]),
      // 'file': new FormControl(''),
      // 'image': new FormControl('')

    })
  }

 
 


  addSwatches() {
  
    // this.router.navigate(['/swatches/list-swatches'])
    let url = `wallet/wallet/add-dashboard-ramaining`
    let data = {
      // 'author': this.addSwatchesForm.value.author,
      'name': this.addSwatchesForm.value.title,
      'createTime': Date.parse(this.addSwatchesForm.value.date),
      'updationTime' : Date.parse(this.addSwatchesForm.value.date)
      // 'quantity': this.addSwatchesForm.value.quantity,
      // 'image' : this.imageUrl
    }
    this.commonService.showSpinner();
    this.commonService.post(url,data).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.status == 200) {
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/plan-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }



 

   // Image Functionality Start Here
   uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      // this.commonService.hideSpinner();
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }

}
